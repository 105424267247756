<template>
  <div class="searchOrder">
    <div class="list-title">Order List</div>
    <a-card class="search-card" :body-style="{ padding: '16px 16px 0 16px' }">
      <a-form ref="formRef" :model="searchForm" layout="vertical" autocomplete="off">
        <a-row :gutter="[20, 20]">
          <a-col :span="10">
            <a-form-item field="lookFor" label="What are you looking for?">
              <a-input-search v-model="searchForm.lookFor"
                placeholder="Enter keyword to search. Eg:#PO、Recipient、Email、etc." allow-clear
                @keydown.enter="handleSubmit" />
            </a-form-item>
          </a-col>
          <a-col :span="10">
            <a-form-item field="category" label="Category">
              <a-select v-model="searchForm.category" @change="selsecCategory">
                <a-option value="regular">Regular Orders</a-option>
                <a-option value="manifest">Manifest</a-option>
                <a-option value="cc">Call Back & Change Address</a-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="4" class="operate">
            <a-popconfirm content="Are you sure you want to reset?" type="warning" ok-text="Reset" cancel-text="Cancel"
              @ok="handleReset" class="warnPop">
              <a-button shape="round">Reset
              </a-button>
            </a-popconfirm>
            <a-button type="primary" html-type="submit" @click="handleSubmit" shape="round">Search
            </a-button>
            <a-button shape="circle" @click="showSearch">
              <icon-double-down v-show="flag === false" />
              <icon-double-up v-show="flag === true" />
            </a-button>
          </a-col>
        </a-row>
        <div :style="{ display: flag === true ? 'block' : 'none' }">
          <a-row :gutter="[20, 20]">
            <a-col :span="5" style="width:190px;">
              <a-form-item field="service">
                <template #label>
                  Service
                  <a-tooltip>
                    <template #content>
                      <div>
                        Filtrate quoted orders for bulk payment.
                      </div>
                    </template>
                    <icon-question-circle style="cursor: pointer"></icon-question-circle>
                  </a-tooltip>
                </template>
                <a-select v-model="searchForm.service" multiple v-if="listType === 1" :max-tag-count="2" allow-clear>
                  <a-option :value="0">N/A</a-option>
                  <a-option :value="101">Ground</a-option>
                  <a-option :value="102">Home</a-option>
                </a-select>
                <a-select v-model="searchForm.service" v-else multiple :max-tag-count="2" allow-clear>
                  <a-option :value="101">Ground</a-option>
                  <a-option :value="102">Home</a-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item field="orderType" label="Order Type">
                <a-select v-model="searchForm.orderType" multiple>
                  <a-option value="0">SPS </a-option>
                  <a-option value="1">MPS</a-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="3">
              <a-form-item field="tracking" label="Tracking">
                <a-input v-model="searchForm.tracking" placeholder="E.g. 1132299310964" />
              </a-form-item>
            </a-col>
            <a-col :span="3" id="recipient">
              <a-form-item field="recipientZip" label="Recipient Zip">
                <a-config-provider :locale="enUS">
                  <!-- <a-select allow-search allow-clear :filter-option="false" v-model="searchForm.recipientZip"
                    @change="fromChange" @search="fromZipSearch" placeholder="Please Enter">
                    <a-option v-for="item of fromOptions" :value="item.zip" :key="item">{{ item.zip }}</a-option>
                  </a-select> -->
                  <a-select allow-search placeholder="E.g. 00811" v-model="searchForm.recipientZip" @change="fromChange"
                    @search="fromZipSearch" allow-clear :loading="fromLoading" :options="fromOptions" autocomplete="off">
                    <template #empty>
                      <a-empty v-if="(fromIsempty === true)" />
                      <div class="empty" v-if="(fromZiperror === true)">
                        <a-space>
                          <icon-info-circle />
                          Zip error
                        </a-space>
                      </div>
                      <div class="noarea" v-if="(fromNoarea === true)">
                        <icon-empty />
                        Not in the delivery service area
                      </div>
                    </template>
                  </a-select>
                </a-config-provider>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item field="orderId" label="Order ID">
                <a-input v-model="searchForm.orderId" placeholder="E.g. 202208213849273" />
              </a-form-item>
            </a-col>
            <a-col :span="5">
              <a-form-item field="createTime" label="Create Time">
                <a-config-provider :locale="enUS">
                  <a-range-picker v-model="searchForm.createTime" style="width:100%;" :placeholder="['From', 'To']"
                    :format=dateFormate />
                </a-config-provider>
              </a-form-item>
            </a-col>
          </a-row>
        </div>
      </a-form>
    </a-card>
    <div class="menu-select">
      <div class="nav-cont">
        <div class="statType">{{ titleShow }}</div>
        <div class="topoperate">
          <a-menu mode="horizontal" @menu-item-click="clickMenu" :selected-keys="navselect">
            <a-menu-item :key="val" v-for="val, k in navShow.val">{{ k }}</a-menu-item>
          </a-menu>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <a-config-provider :locale="enUS">
      <div class="content-list">
        <div v-if="listType === 6 || listType === 9 || listType === 10">
          <a-table :columns="listManifestColumns" :data="listData.value" :bordered="false"
            style="border-radius:0 0 8px 8px;" :pagination="false" @select-all="selectAllManifest"
            @select="selectManifest" :scroll="{ y: tableHeight.value }" :row-selection="rowSelectionManifest"
            :selected-keys="manifestArr" :loading="loading">
            <template #price="{ record }">
              <span>${{ toThousands(record.price) }}</span>
            </template>
            <template #empty>
              <div v-if="listData.value.length === 0 && loading === false"
                :style="{ height: tableHeight.value - 50 + 'px' }" class="empty-cont">
                <!-- <a-empty /> -->
                <img src="../../../assets/images/empty/listempty.svg" alt="">
                <div>No results found</div>
              </div>
              <div v-if="loading === true" :style="{ height: tableHeight.value - 50 + 'px' }" class="skeleton-box">
              </div>
            </template>
            <template #status_desc="{ record }">
              <a-tag color="orange" v-if="record.status_desc === 'Created'">Created</a-tag>
              <a-tag color="blue" v-else-if="record.status_desc === 'Picked up'">Picked Up</a-tag>
              <a-tag v-else>{{ record.status_desc }}</a-tag>
            </template>
            <template #action="{ record }">
              <div v-show="record.ponumber === undefined">
                <a-space>
                  <a-tooltip content="Label">
                    <a-button shape="circle" @click="getmanifestLabel(record.key)">
                      <icon-eye />
                    </a-button>
                  </a-tooltip>
                  <a-tooltip content="Shipments">
                    <a-button shape="circle" @click="goShipment(record.id, record.name)">
                      <icon-archive />
                    </a-button>
                  </a-tooltip>
                </a-space>
              </div>
              <a-tooltip content="Detail">
                <a-button shape="circle" @click="godetail(record.id)" v-show="record.ponumber !== undefined">
                  <icon-right />
                </a-button>
              </a-tooltip>
            </template>
          </a-table>
        </div>
        <div v-else-if="listType === 7 || listType === 8">
          <a-table :columns="listcallColumns" :data="listData.value" :bordered="false" :pagination="false"
            @select-all="changmsg" @select="selectVal" :scroll="{ x: tableWidth, y: tableHeight.value }"
            :loading="loading">
            <template #empty>
              <div v-if="listData.value.length === 0 && loading === false"
                :style="{ height: tableHeight.value - 35 + 'px' }" class="empty-cont">
                <img src="../../../assets/images/empty/listempty.svg" alt="">
                <div>No results found</div>
              </div>
              <div v-if="loading === true" :style="{ height: tableHeight.value - 35 + 'px' }" class="skeleton-box">
              </div>
            </template>
            <template #trackingid="{ record }">
              <span v-if="record.trackingid">
                {{ record.trackingid }}
                <a-tooltip content="Copy">
                  <icon-copy class="margin-l-10" @click.stop="copyVal(record.trackingid)" />
                </a-tooltip>
              </span>
              <span v-else>N/A</span>
            </template>
            <template #ponumber="{ record }">
              {{ record.ponumber }}
              <a-tooltip content="Copy">
                <icon-copy class="margin-l-10" @click.stop="copyVal(record.ponumber)" />
              </a-tooltip>
            </template>
            <template #orderid="{ record }">
              {{ record.orderid }}
              <a-tooltip content="Copy">
                <icon-copy class="margin-l-10" @click.stop="copyVal(record.orderid)" />
              </a-tooltip>
            </template>
            <template #originalponumber="{ record }">
              <span @click="godetail(record.original_order_id)" class="originalOrder">
                {{ record.original }}
              </span>
              <a-tooltip content="Copy">
                <icon-copy class="margin-l-10" @click.stop="copyVal(record.original)" />
              </a-tooltip>
            </template>
            <template #price="{ record }">
              <div v-if="record.price > 0">
                <a-tooltip position="right">
                  <a-tag>${{ toThousands(record.price) }}</a-tag>
                  <template #content>
                    <div v-for="val, k, i in record.quote_detail" :key="i">
                      <div>
                        <span v-if="k == 'Base Charge' || k == 'Surcharge'">{{ k }}:</span>
                        <span v-else>-{{ k }}:</span>
                        <span> ${{ val }}</span>
                      </div>
                    </div>
                  </template>
                </a-tooltip>
              </div>
              <a-tag v-else>${{ toThousands(record.price) }}</a-tag>
            </template>
            <template #reference="{ record }">
              <span v-if="record.reference">{{ record.reference }}</span>
              <span v-else>N/A</span>
            </template>
            <template #incoivenum="{ record }">
              <span v-if="record.incoivenum">{{ record.incoivenum }}</span>
              <span v-else>N/A</span>
            </template>
            <template #shipdate="{ record }">
              <span v-if="record.shipdate">{{ record.shipdate }}</span>
              <span v-else>N/A</span>
            </template>
            <template #recipientfulladdress="{ record }">
              <div v-if="record.recipientaddress1">
                <div>{{ record.recipientaddress1 }}</div>
                <div v-if="record.recipientaddress2">{{ record.recipientaddress2 }}</div>
                <div>
                  {{ record.recipientcity }},{{ record.recipientstate }},{{ record.recipientzip }},{{
                    record.recipientcountry
                  }}
                </div>
              </div>
            </template>
            <template #recipientaddress2="{ record }">
              <span v-if="record.recipientaddress2">{{ record.recipientaddress2 }}</span>
              <span v-else>N/A</span>
            </template>
            <template #shipperfulladdress="{ record }">
              <div v-if="record.shipperaddress1">
                <div>{{ record.shipperaddress1 }}</div>
                <div v-if="record.shipperaddress2">{{ record.shipperaddress2 }}</div>
                <div>{{ record.shippercity }},{{ record.shipperstates }},{{ record.shipperzip }},{{
                  record.shippercountry
                }}</div>
              </div>
            </template>
            <template #shipperaddress2="{ record }">
              <span v-if="record.shipperaddress2"></span>
              <span v-else>N/A</span>
            </template>
            <template #shippercompany="{ record }">
              <span v-if="record.shippercompany">{{ record.shippercompany }}</span>
              <span v-else>N/A</span>
            </template>
            <template #recipientcompany="{ record }">
              <span v-if="record.recipientcompany">{{ record.recipientcompany }}</span>
              <span v-else>N/A</span>
            </template>
            <template #action="{ record }">
              <a-space>
                <a-tooltip content="Label">
                  <a-button
                    v-show="listType === 2 || listType === 3 || listType === 4 || listType === 5 || listType === 7 || listType === 8"
                    shape="circle" @click="preview(record.view)">
                    <icon-eye />
                  </a-button>
                </a-tooltip>
                <a-tooltip content="Detail">
                  <a-button shape="circle" @click="godetail(record.key)">
                    <icon-right />
                  </a-button>
                </a-tooltip>
              </a-space>
            </template>
          </a-table>
        </div>
        <div v-else>
          <a-table :columns="listColumns" :data="listData.value" :bordered="false" :row-selection="rowSelection"
            :pagination="false" @select-all="changmsg" @select="selectVal"
            :scroll="{ x: tableWidth, y: tableHeight.value }" :selected-keys="selectArr.val" :loading="loading">
            <template #empty>
              <div v-if="listData.value.length === 0 && loading === false"
                :style="{ height: tableHeight.value - 35 + 'px' }" class="empty-cont">
                <img src="../../../assets/images/empty/listempty.svg" alt="">
                <div>No results found</div>
              </div>
              <div v-if="loading === true" :style="{ height: tableHeight.value - 35 + 'px' }" class="skeleton-box">
              </div>
            </template>
            <template #ponumber="{ record }">
              {{ record.ponumber }}
              <a-tooltip content="Copy">
                <icon-copy class="margin-l-10" @click.stop="copyVal(record.ponumber)" />
              </a-tooltip>
            </template>
            <template #price="{ record }">
              <div v-if="record.price > 0">
                <a-tooltip position="right">
                  <a-tag>${{ toThousands(record.price) }}</a-tag>
                  <template #content>
                    <div v-for="val, k, i in record.quote_detail" :key="i">
                      <div>
                        <span v-if="k == 'Base Charge' || k == 'Surcharge'">{{ k }}:</span>
                        <span v-else>-{{ k }}:</span>
                        <span> {{ val }}</span>
                      </div>
                    </div>
                  </template>
                </a-tooltip>
              </div>
              <a-tag v-else>${{ toThousands(record.price) }}</a-tag>
            </template>
            <template #orderid="{ record }">
              {{ record.orderid }}
              <a-tooltip content="Copy">
                <icon-copy class="margin-l-10" @click.stop="copyVal(record.orderid)" />
              </a-tooltip>
            </template>
            <template #trackingid="{ record }">
              <span v-if="record.trackingid">
                {{ record.trackingid }}
                <a-tooltip content="Copy">
                  <icon-copy class="margin-l-10" @click.stop="copyVal(record.trackingid)" />
                </a-tooltip>
              </span>
              <span v-else>N/A</span>
            </template>
            <template #reference="{ record }">
              <span v-if="record.reference">{{ record.reference }}</span>
              <span v-else>N/A</span>
            </template>
            <template #incoivenum="{ record }">
              <span v-if="record.incoivenum">{{ record.incoivenum }}</span>
              <span v-else>N/A</span>
            </template>
            <template #shipdate="{ record }">
              <span v-if="record.shipdate">{{ record.shipdate }}</span>
              <span v-else>N/A</span>
            </template>
            <template #recipientfulladdress="{ record }">
              <div>{{ record.recipientaddress1 }}</div>
              <div v-if="record.recipientaddress2">{{ record.recipientaddress2 }}</div>
              <div>
                {{ record.recipientcity }},{{ record.recipientstate }},{{ record.recipientzip }},{{
                  record.recipientcountry
                }}
              </div>
            </template>
            <template #recipientaddress2="{ record }">
              <span v-if="record.recipientaddress2">{{ record.recipientaddress2 }}</span>
              <span v-else>N/A</span>
            </template>
            <template #shipperfulladdress="{ record }">
              <div>{{ record.shipperaddress1 }}</div>
              <div v-if="record.shipperaddress2">{{ record.shipperaddress2 }}</div>
              <div>{{ record.shippercity }},{{ record.shipperstates }},{{ record.shipperzip }},{{
                record.shippercountry
              }}</div>
            </template>
            <template #shipperaddress2="{ record }">
              <span v-if="record.shipperaddress2"></span>
              <span v-else>N/A</span>
            </template>
            <template #shippercompany="{ record }">
              <span v-if="record.shippercompany">{{ record.shippercompany }}</span>
              <span v-else>N/A</span>
            </template>
            <template #recipientcompany="{ record }">
              <span v-if="record.recipientcompany">{{ record.recipientcompany }}</span>
              <span v-else>N/A</span>
            </template>
            <template #action="{ record }">
              <a-space>
                <a-tooltip content="Edit">
                  <a-button v-show="listType === 1" shape="circle" @click="goedit(record.key)">
                    <icon-edit />
                  </a-button>
                </a-tooltip>
                <a-tooltip content="Label">
                  <a-button
                    v-show="listType === 2 || listType === 3 || listType === 4 || listType === 5 || listType === 7 || listType === 8"
                    shape="circle" @click="preview(record.view)">
                    <icon-eye />
                  </a-button>
                </a-tooltip>
                <a-tooltip content="Print">
                  <a-button v-show="listType === 2" type="primary" shape="circle" @click="print(record.view, record.key)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.5 0C2.94772 0 2.5 0.447715 2.5 1V3.5H1.5C0.6775 3.5 0 4.1775 0 5V9C0 9.55229 0.447715 10 1 10H2.5V11C2.5 11.5523 2.94772 12 3.5 12H8.5C9.05229 12 9.5 11.5523 9.5 11V10H11C11.5523 10 12 9.55229 12 9V5C12 4.1775 11.3225 3.5 10.5 3.5H9.5V1C9.5 0.447715 9.05228 0 8.5 0H3.5ZM3.5 1H8.5V3.5H3.5V1ZM1.5 4.5H10.5C10.783 4.5 11 4.717 11 5V9H9.5V7H2.5V9H1V5C1 4.717 1.217 4.5 1.5 4.5ZM2 5C1.725 5 1.5 5.225 1.5 5.5C1.5 5.775 1.725 6 2 6C2.275 6 2.5 5.775 2.5 5.5C2.5 5.225 2.275 5 2 5ZM3.5 8H8.5V11H3.5V8Z"
                        fill="white" />
                    </svg>
                  </a-button>
                </a-tooltip>
                <a-tooltip content="Detail">
                  <a-button @click="godetail(record.key)" shape="circle" v-if="listType != 1">
                    <icon-right />
                  </a-button>
                </a-tooltip>
              </a-space>
            </template>
          </a-table>
        </div>
        <!--设置展示的弹框 -->
        <div class="transfer" v-show="setFlag">
          <div style="width:220px" class="setList">
            <a-table :data="leftData" @change="handleChange" :draggable="{ type: 'handle', width: 40 }"
              :row-selection="setSelection" :scroll="{ y: 200 }" :bordered="false" :pagination="false"
              @select-all="selectAll" @select="setSelect" :selected-keys="defaultSelect">
              <template #columns>
                <a-table-column title="List Setting" data-index="title"></a-table-column>
              </template>
            </a-table>
            <div class="transfer-btn">
              <a-button size="mini" @click="setDefault">default</a-button>
              <a-tooltip content="Please select at least three fields." position="left">
                <a-button type="primary" class="normal-btn" size="mini" @click="setVal"
                  :disabled="defaultSelect.length < 3">
                  save
                </a-button>
              </a-tooltip>
            </div>
            <div class="SelectNum">
              {{ defaultSelect.length }} / {{ leftData.length }}
            </div>
          </div>
        </div>
      </div>
    </a-config-provider>
    <div class="add-page" :style="selectArr.val.length > 0 ? 'padding-bottom:146px' : 'padding-bottom:24px'">
      <a-button type="primary" shape="round" @click="$router.push('/order/add/addneworder')">+ Add New Order
      </a-button>
      <div class="page">
        <div v-show="searchForm.category === 'regular' || searchForm.category === 'cc'">
          <a-space>
            <span>Total : {{ orderTotal }} orders</span>
            <a-button :disabled="currentpage == 1" @click="clickMinus" type="text">
              <icon-left />
            </a-button>
            <span class="currentpage">{{ currentpage }}</span>
            <div>/</div>
            <div>{{ totalPages }}</div>
            <a-button :disabled="currentpage == totalPages || totalPages == 0" @click="clickAdd" type="text">
              <icon-right />
            </a-button>
            <a-select :style="{ width: '134px' }" v-model="pageSize" @change="selectPage">
              <a-option :value="20">20 / Page</a-option>
              <a-option :value="50">50 / Page</a-option>
              <a-option :value="100">100 / Page</a-option>
              <a-option :value="200">200 / Page</a-option>
            </a-select>
            <div class="set">
              <a-button shape="circle" id="setBtn"
                v-show="listType === 1 || listType === 2 || listType === 3 || listType === 4 || listType === 5 || listType === 7 || listType === 8">
                <icon-settings />
              </a-button>
            </div>
          </a-space>
        </div>
        <div v-show="searchForm.category === 'manifest'">
          <a-space>
            <span>Total : {{ totalManifests }} manifests</span>
            <a-button :disabled="currentpageManifest == 1" @click="clickManifestMinus" type="text">
              <icon-left />
            </a-button>
            <span class="currentpage">{{ currentpageManifest }}</span>
            <div>/</div>
            <div>{{ totalPagesManifest }}</div>
            <a-button :disabled="currentpageManifest == totalPagesManifest || totalPagesManifest == 0"
              @click="clickManifestAdd" type="text">
              <icon-right />
            </a-button>
            <a-select :style="{ width: '134px' }" v-model="pageSizeManifest" @change="selectPageManifest">
              <a-option :value="20">20 / Page</a-option>
              <a-option :value="50">50 / Page</a-option>
              <a-option :value="100">100 / Page</a-option>
              <a-option :value="200">200 / Page</a-option>
            </a-select>
          </a-space>
        </div>
      </div>
    </div>
  </div>
  <!-- 操作按钮 -->
  <div class="addnew-footer" v-if="listType !== 7 && listType !== 8 && selectArr.val.length > 0">
    <div class="items">
      <a-space>
        <a-checkbox v-model="checkedValue" @change="changmsg"
          v-if="listType === 1 || listType === 2 || listType === 3 || listType === 4 || listType === 5" class="itemNum">
          {{ selectArr.val.length }} items
        </a-checkbox>
        <a-popconfirm content="Are you sure you want to delete?" type="warning" ok-text="Delete" cancel-text="Cancel"
          @ok="moreDelete" style="width: 276px;" class="deletePop">
          <a-button v-if="listType === 1 && isDisable === false" status="danger" size="large" shape="round">Delete
          </a-button>
        </a-popconfirm>
        <a-button v-if="listType === 2 && isDisable === false" type="primary" size="large" @click="morePrint"
          shape="round">Print
        </a-button>
        <a-button v-if="listType === 3 && isDisable === false" type="primary" size="large" @click="moreShipped"
          shape="round">Mark as
          Shipped</a-button>
        <a-popconfirm content="Are you sure you want to duplicate?" type="warning" ok-text="Duplicate" cancel-text="Back"
          @ok="moreDuplicate" class="warnPop">
          <span v-if="listType === 2 || listType === 3 || listType === 4 || listType === 5">
            <a-button size="large" v-if="isDisable === false" shape="round">
              Duplicate
            </a-button>
          </span>
        </a-popconfirm>
        <a-popconfirm content="Are you sure you want to create return order(s)?" type="warning" ok-text="Create"
          cancel-text="Back" @ok="moreReturn" class="warnPop">
          <span v-if="listType === 2 || listType === 3 || listType === 4 || listType === 5">
            <a-button shape="round" size="large" v-if="returnDisable === true">
              Create Return Order
            </a-button>
          </span>
        </a-popconfirm>

        <a-popconfirm content="Are you sure to create manifest?" type="warning" ok-text="Create" cancel-text="Cancel"
          @ok="manifestOk">
          <a-button v-if="listType === 3 && isDisable === false" type="outline" size="large" shape="round">Create
            a
            Manifest</a-button>
        </a-popconfirm>
        <a-popconfirm content="Are you sure to cancel selected order(s)?" type="warning" ok-text="Cancel Order(s)"
          cancel-text="Back" @ok="moreCancel" class="warnPop">
          <span v-if="listType === 2 || listType === 3 || listType === 4">
            <a-button status="warning" size="large" v-if="isDisable === false" shape="round">Cancel
            </a-button>
          </span>
        </a-popconfirm>
      </a-space>
    </div>
    <div v-if="listType === 1">
      <div class="quanty">
        <a-space>
          <a-tooltip position="right">
            <a-button :disabled="totalPayPrice === '0.00'">Est. Cost:</a-button>
            <template #content>
              <div v-for="val, k, i in payDetail" :key="i">
                <div>
                  <span>{{ k }}:</span>
                  <span> {{ val }}</span>
                </div>
              </div>
            </template>
          </a-tooltip>
          <div class="total-q-num">${{ totalPayPrice }}</div>
          <a-button shape="round" type="primary" @click="getPayLabel" :disabled="payDisable">Pay</a-button>
        </a-space>
      </div>
      <div class="pay-tip">
        By paying your order ,you agree to our<br />
        <span>company privacy </span>and <span>conditions of use.</span>
      </div>
    </div>
  </div>
  <div class="addnew-footer" v-if="listType !== 7 && listType !== 8 && manifestSelect.length > 0">
    <div class="items">
      <a-space>
        <a-checkbox v-model="checkedAllManifest" @change="selectAllManifest" class="itemNum">
          {{ manifestSelect.length }} items
        </a-checkbox>
        <a-popconfirm content="Are you sure to remove order(s) from manifest?" type="warning" ok-text="Remove"
          cancel-text="Cancel" @ok="removeManifet" class="warnPop" style="width:276px;">
          <span>
            <a-button size="large" status="warning" shape="round" v-if="manifestSelect.length > 0">Remove</a-button>
          </span>
        </a-popconfirm>
        <a-popconfirm content="Are you sure to cancel selected order(s)?" type="warning" ok-text="Cancel Order(s)"
          cancel-text="Back" @ok="cancelManifestMore" class="warnPop" style="width:276px;">
          <span>
            <a-button shape="round" size="large" status="warning" v-if="manifestSelect.length > 0">Cancel</a-button>
          </span>
        </a-popconfirm>
      </a-space>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from 'vue'
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import { orderList, orderBatchHandle, manifestList, manifestPdf, markAsShipped, manifestAdd, printLabel, getZipCode, manifestRemove, getMultiOrderQuoteDetail } from '@/api/order.js'
import { toThousands } from '@/utils/handleNumber.js'
import { Message, Notification, Button } from '@arco-design/web-vue'
import {
  IconRight, IconLeft, IconQuestionCircle, IconDoubleDown, IconDoubleUp, IconSettings, IconEdit, IconEye, IconArchive, IconCopy, IconInfoCircle, IconEmpty
} from '@arco-design/web-vue/es/icon'
import local from '@/utils/local'
// 使用loading
import { load } from '@/utils/loading.js'
// 使用pinia
import { mainStore } from '@/store/index.js'
import { storeToRefs } from 'pinia'
const loading = ref(false)
const store = mainStore()
const { Golist } = storeToRefs(store)
const formRef = ref(null)
const router = useRouter()
const route = useRoute()
let dateFormate = ref('MM/DD/YYYY')
// 搜索展开隐藏
const flag = ref(false)
let timeFormate = JSON.parse(local.get('userInfo')).date_format
console.log(timeFormate)
if (timeFormate === 1) {
  dateFormate.value = 'MM/DD/YYYY'
} else if (timeFormate === 2) {
  dateFormate.value = 'DD/MM/YYYY'
} else if (timeFormate === 3) {
  dateFormate.value = 'YYYY/MM/DD'
}
// 搜索内容
let searchForm = reactive({
  lookFor: '',
  category: 'regular',
  service: [0, 101, 102],
  orderType: ['0', '1'],
  tracking: '',
  recipientZip: '',
  orderId: '',
  createTime: ['', '']
})
// 默认
const noDefault = ref(null)
let selectArr = reactive({
  val: []
})
let selectPayArr = reactive({
  val: []
})
let isDisable = ref(true)
let payDisable = ref(true)

// 导航选中
let navselect = ref([1])
// 导航
const navList = reactive({
  regluar: {
    'Awaiting Payment': 1,
    'Awaiting Shipment': 2,
    'Printed': 3, // eslint-disable-line quote-props
    'Shipped': 4, // eslint-disable-line quote-props
    'Canceled': 5 // eslint-disable-line quote-props
  },
  manifest: {
    'All': 6, // eslint-disable-line quote-props
    'Created': 9, // eslint-disable-line quote-props
    'Picked Up': 10
  },
  cc:
  {
    'Called Back': 7,
    'Change Address': 8
  }
})
let navShow = reactive({ val: {} })
navShow.val = navList.regluar
let titleShow = ref('Regular Orders')
// 提交搜索
const handleSubmit = () => {
  // 清空
  if (searchForm.category === 'regular') {
    listType.value = 1
    if (noDefault.value !== 1) {
      listType.value = noDefault.value
    }
    navselect.value = [listType.value]
    navShow.val = navList.regluar
    titleShow.value = 'Regular Orders'
    currentpage.value = 1
    getList()
  } else if (searchForm.category === 'manifest') {
    listType.value = 6
    if (noDefault.value !== 6) {
      listType.value = noDefault.value
    }
    navselect.value = [listType.value]
    navShow.val = navList.manifest
    titleShow.value = 'Manifest'
    currentpage.value = 1
    getManifestList()
  } else {
    listType.value = 7
    if (noDefault.value !== 7) {
      listType.value = noDefault.value
    }
    navselect.value = [listType.value]
    navShow.val = navList.cc
    titleShow.value = 'Call Back & Change Address'
    currentpage.value = 1
    getList()
  }
}
// regular表格配置
const listColumns = ref([
  {
    title: 'Tracking',
    dataIndex: 'trackingid',
    slotName: 'trackingid',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 150
  },
  {
    title: 'PO Number',
    dataIndex: 'ponumber',
    slotName: 'ponumber',
    sortable: {
      sortDirections: ['ascend', 'descend']
    },
    width: 185
  },
  {
    title: 'Order ID',
    dataIndex: 'orderid',
    slotName: 'orderid',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 200
  },
  {
    title: 'Created At',
    dataIndex: 'createat',
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b, extra) => {
        let sortVal
        if (a.key > b.key) {
          sortVal = 1
        } else if (a.key < b.key) {
          sortVal = -1
        } else {
          sortVal = 0
        }
        return (extra.direction === 'ascend') ? sortVal : -sortVal
      }
    },
    width: 200
  },
  {
    title: 'Service Type',
    dataIndex: 'service',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 150
  },
  {
    title: 'Shipment',
    dataIndex: 'shipment',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 120
  },
  {
    title: 'Est. Cost',
    dataIndex: 'price',
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b, extra) => {
        let sortVal;
        if (parseFloat(a.price) > parseFloat(b.price)) {
          sortVal = 1;
        } else if (parseFloat(a.price) < parseFloat(b.price)) {
          sortVal = -1;
        } else {
          sortVal = 0;
        }
        return (extra.direction === 'ascend') ? sortVal : -sortVal;
      }
    },
    slotName: 'price',
    width: 150
  },
  {
    title: 'Recipient Name',
    dataIndex: 'recipientname',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 180
  },
  {
    title: 'Action',
    dataIndex: 'action',
    slotName: 'action',
    fixed: 'right',
    width: 150
  }
])
// manifest表格配置
const listManifestColumns = ref([
  {
    title: 'Created At',
    dataIndex: 'created_at',
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b, extra) => {
        let sortVal
        if (a.id > b.id) {
          sortVal = 1
        } else if (a.id < b.id) {
          sortVal = -1
        } else {
          sortVal = 0
        }
        return (extra.direction === 'ascend') ? sortVal : -sortVal
      }
    }
  },
  {
    title: '#ID',
    dataIndex: 'id_dec',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Status',
    dataIndex: 'status_desc',
    slotName: 'status_desc',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Shipment Quantity',
    dataIndex: 'shipment_count',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Total Est. Cost',
    dataIndex: 'price',
    slotName: 'price',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Action',
    dataIndex: 'action',
    slotName: 'action'
  }
])
// changaddress&callBack 表格配置
const listcallColumns = ref([
  {
    key: 'trackingid',
    title: 'Tracking',
    dataIndex: 'trackingid',
    slotName: 'trackingid',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 150
  },
  {
    key: 'ponumber',
    title: 'PO Number',
    dataIndex: 'ponumber',
    slotName: 'ponumber',
    sortable: {
      sortDirections: ['ascend', 'descend']
    },
    width: 200
  },
  {
    key: 'orderid',
    title: 'Order ID',
    slotName: 'orderid',
    dataIndex: 'orderid',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 200
  },
  {
    key: 'original',
    title: 'Original Order Id',
    dataIndex: 'original',
    sortable: { sortDirections: ['ascend', 'descend'] },
    slotName: 'originalponumber',
    width: 210
  },
  {
    key: 'createat',
    title: 'Created At',
    dataIndex: 'createat',
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b, extra) => {
        let sortVal
        if (a.key > b.key) {
          sortVal = 1
        } else if (a.key < b.key) {
          sortVal = -1
        } else {
          sortVal = 0
        }
        return (extra.direction === 'ascend') ? sortVal : -sortVal
      }
    },
    width: 200
  },
  {
    key: 'service',
    title: 'Service Type',
    dataIndex: 'service',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 150
  },
  {
    key: 'shipment',
    title: 'Shipment',
    dataIndex: 'shipment',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 120
  },
  {
    key: 'price',
    title: 'Est. Cost',
    dataIndex: 'price',
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b, extra) => {
        let sortVal;
        if (parseFloat(a.price) > parseFloat(b.price)) {
          sortVal = 1;
        } else if (parseFloat(a.price) < parseFloat(b.price)) {
          sortVal = -1;
        } else {
          sortVal = 0;
        }
        return (extra.direction === 'ascend') ? sortVal : -sortVal;
      }
    },
    slotName: 'price',
    width: 150
  },
  {
    key: 'recipientname',
    title: 'Recipient Name',
    dataIndex: 'recipientname',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 180
  },
  {
    title: 'Action',
    dataIndex: 'action',
    fixed: 'right',
    slotName: 'action',
    width: 100
  }
])
// 表格数据
let listData = reactive({ value: [] })
// 表格复选框
const rowSelection = {
  type: 'checkbox',
  showCheckedAll: true
}
// manifest 复选框
const rowSelectionManifest = reactive({
  type: 'checkbox',
  showCheckedAll: true,
  checkStrictly: false
})
const listType = ref(1)
const clickMenu = (val) => {
  selectArr.val = []
  if (searchForm.service.indexOf(0) !== -1 && val !== 1) {
    searchForm.service = [101, 102]
  }
  if (searchForm.service.toString() === [101, 102].toString() && val === 1) {
    searchForm.service = [0, 101, 102]
  }
  if (val === 6 || val === 9 || val === 10) {
    navselect.value = [val]
    noDefault.value = val
    currentpageManifest.value = 1
    if (val === 6) {
      manifestStatus.value = null
    } else if (val === 9) {
      manifestStatus.value = 1
    } else {
      manifestStatus.value = 3
    }
    getManifestList()
  } else {
    noDefault.value = val
    navselect.value = [val]
    currentpage.value = 1
    listType.value = val
    getList()
  }
}
// 上传id
let uploadId = route.query.uploadId
// 当前页
const currentpage = ref(1)
// 数据是否都加载完毕
const dataOver = ref(false)
// 总的order
const orderTotal = ref(0)
// 总页数
const totalPages = ref(0)
// 每页几条
const pageSize = ref(20)
// 获取表格数据
const getList = async () => {
  loading.value = true
  isDisable.value = true
  payDisable.value = true
  selectArr.val = []
  if (searchForm.createTime === undefined) {
    searchForm.createTime = ['', '']
  }
  const msg = await orderList({
    status: listType.value,
    upload_id: uploadId,
    sort: null,
    page: currentpage.value,
    keyword: searchForm.lookFor,
    category: searchForm.category,
    service_type: searchForm.service,
    mps: searchForm.orderType,
    tracking_id: searchForm.tracking,
    to_zip: searchForm.recipientZip,
    unique_id: searchForm.orderId,
    created_at_start: searchForm.createTime[0],
    created_at_end: searchForm.createTime[1],
    pageCount: pageSize.value
  })
  if (msg.code === 0) {
    loading.value = false
    store.Golist = null
    // 清空搜索
    orderTotal.value = msg.data.totalCount
    totalPages.value = msg.data.totalPages
    currentpage.value = msg.data.currentPage
    let info = msg.data.data
    if (info.length < 20) {
      dataOver.value = true
    }
    let currentData = []
    for (let i = 0; i < info.length; i++) {
      currentData.push({
        key: info[i].id,
        trackingid: info[i].tracking_id,
        ponumber: info[i].ponumber,
        orderid: info[i].unique_id,
        createat: info[i].created_at,
        service: info[i].service_type_desc,
        serviceType: info[i].service_type,
        shipment: info[i].shipment_count,
        price: info[i].estimate_shipping_charge,
        recipientname: info[i].to_name,
        ordertype: info[i].order_type,
        incoivenum: info[i].invoice_number,
        reference: info[i].reference,
        signiture: info[i].signature_option_show,
        droptype: info[i].dropoff_type_show,
        shipdate: info[i].ship_date,
        totalweight: info[i].weight,
        recipientaddress1: info[i].to_street1,
        recipientaddress2: info[i].to_street2,
        recipientcity: info[i].to_city,
        recipientstate: info[i].to_state,
        recipientzip: info[i].to_zip,
        recipientcountry: info[i].to_country,
        recipientphone: info[i].to_phone,
        recipientcompany: info[i].to_company,
        shippername: info[i].from_name,
        shipperaddress1: info[i].from_street1,
        shipperaddress2: info[i].from_street2,
        shippercity: info[i].from_city,
        shipperstate: info[i].from_state,
        shipperzip: info[i].from_zip,
        shippercountry: info[i].from_country,
        shipperphone: info[i].from_phone,
        shippercompany: info[i].from_company,
        shipperstates: info[i].from_state,
        view: info[i].label_file_name,
        original_order_id: info[i].original_order_id,
        original: info[i].original_order_unique_id,
        quote_detail: info[i].quote_detail
      })
    }
    listData.value = currentData
    local.set('searchData', JSON.stringify({
      formData: searchForm,
      title: titleShow.value,
      nav: navShow.val,
      navselect: navselect.value,
      listType: listType.value,
      pageSize: pageSize.value,
      currentpage: currentpage.value,
      flag: flag.value
    }
    ))
  } else {
    loading.value = false
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 选择Category
const selsecCategory = (val) => {
  if (val === 'regular') {
    pageSize.value = 20
    leftData.value = regularData
    defaultSelect.value = ['trackingid', 'ponumber', 'orderid', 'createat', 'service', 'shipment', 'price', 'recipientname']
    setArr.val = ['trackingid', 'ponumber', 'orderid', 'createat', 'service', 'shipment', 'price', 'recipientname']
    noDefault.value = 1
    listType.value = 1
    navselect.value = [1]
    navShow.val = navList.regluar
    titleShow.value = 'Regular Orders'
    currentpage.value = 1
    searchForm.service = [0, 101, 102]
    getList()
  } else if (val === 'cc') {
    pageSize.value = 20
    leftData.value = callChangeData
    defaultSelect.value = ['trackingid', 'ponumber', 'orderid', 'original', 'createat', 'service', 'shipment', 'price', 'recipientname']
    setArr.val = ['trackingid', 'ponumber', 'orderid', 'original', 'createat', 'service', 'shipment', 'price', 'recipientname']
    noDefault.value = 7
    listType.value = 7
    navselect.value = [7]
    navShow.val = navList.cc
    titleShow.value = 'Call Back & Change Address'
    currentpage.value = 1
    searchForm.service = [101, 102]
    getList()
  } else if (val === 'manifest') {
    pageSizeManifest.value = 20
    noDefault.value = 6
    listType.value = 6
    manifestStatus.value = null
    navselect.value = [6]
    navShow.val = navList.manifest
    titleShow.value = 'Manifest'
    currentpage.value = 1
    searchForm.service = [101, 102]
    getManifestList()
  }
}
// 点击页数减
const clickMinus = () => {
  currentpage.value--
  getList()
}
// 点击页数加
const clickAdd = () => {
  currentpage.value++
  getList()
}

// 点击manifest页数减
const clickManifestMinus = () => {
  currentpageManifest.value--
  getManifestList()
}

// 点击manifest 页数加
const clickManifestAdd = () => {
  currentpageManifest.value++
  getManifestList()
}
const currentpageManifest = ref(1)
const totalPagesManifest = ref(0)
const manifestStatus = ref(null)
const totalManifests = ref(0)
const pageSizeManifest = ref(20)
// 获取manifest
const getManifestList = async () => {
  loading.value = true
  manifestArr.value = []
  manifestSelect.value = []
  if (searchForm.service.indexOf(0) !== -1) {
    searchForm.service = [101, 102]
  }
  const msg = await manifestList({
    page: currentpageManifest.value,
    status: manifestStatus.value,
    keyword: searchForm.lookFor,
    category: searchForm.category,
    service_type: searchForm.service,
    mps: searchForm.orderType,
    tracking_id: searchForm.tracking,
    to_zip: searchForm.recipientZip,
    unique_id: searchForm.orderId,
    created_at_start: searchForm.createTime[0],
    created_at_end: searchForm.createTime[1],
    pageCount: pageSizeManifest.value
  })
  if (msg.code === 0) {
    loading.value = false
    currentpageManifest.value = msg.data.current_page
    totalPagesManifest.value = msg.data.page_count
    totalManifests.value = msg.data.count
    listData.value = msg.data.data
    local.set('searchData', JSON.stringify({
      formData: searchForm,
      title: titleShow.value,
      nav: navShow.val,
      navselect: navselect.value,
      listType: listType.value,
      pageSizeManifest: pageSizeManifest.value,
      currentpage: currentpageManifest.value,
      flag: flag.value
    }
    ))
  } else {
    loading.value = false
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// addnew 到Awaiting shipment
// 判断是去哪个页面，然后清掉localStorage
onBeforeRouteLeave((to, from, next) => {
  if (to.path === '/order/list/listdetail') {
    next()
  } else {
    local.del('searchData')
    next()
  }
})
const returnDisable = ref(false)
// 多选
const changmsg = (checked) => {
  checkedValue.value = checked
  if (checked) {
    selectArr.val = []
    // cancel按钮
    listData.value.forEach((item) => {
      selectArr.val.push(item.key)
      isDisable.value = false
    })
    // pay按钮
    if (listData.value.some(val => val.serviceType === 0)) {
      selectPayArr.val = []
      payDisable.value = true
    } else {
      listData.value.forEach((item) => {
        selectPayArr.val.push(item.key)
      })
      payDisable.value = false
    }
    // pay总价和详情
    if (listType.value === 1) {
      getPayDetail()
    }
    // return 按钮
    returnDisable.value = listData.value.every(val => val.shipment === 1)
  } else {
    // cancel按钮
    selectArr.val = []
    isDisable.value = true
    // pay按钮
    selectPayArr.val = []
    payDisable.value = true
    // pay总价和详情
    totalPayPrice.value = '0.00'
    payDetail.value = {}
    // return 按钮
    returnDisable.value = false
  }
}
let noServiceArr = []
const totalPayPrice = ref('0.00')
const payDetail = ref({})
const getPayDetail = async () => {
  const msg = await getMultiOrderQuoteDetail({
    order_ids: selectArr.val
  })
  if (msg.code === 0) {
    if (msg.data.estimate_shipping_charge > 0) {
      totalPayPrice.value = msg.data.estimate_shipping_charge
      payDetail.value = msg.data.detail
    } else {
      totalPayPrice.value = '0.00'
      payDetail.value = {}
    }
  }
}
// 单选
function selectVal (rowKeys, _, record) {
  // cancel按钮
  selectArr.val = rowKeys
  if (rowKeys.length === listData.value.length) {
    checkedValue.value = true
  } else {
    checkedValue.value = false
  }
  if (listType.value === 1 && rowKeys.length > 0) {
    getPayDetail()
  } else {
    totalPayPrice.value = '0.00'
    payDetail.value = {}
  }
  if (rowKeys.length > 0) {
    isDisable.value = false
  } else {
    isDisable.value = true
  }
  // pay按钮
  if (rowKeys.length > 0) {
    if (record.serviceType === 0) {
      noServiceArr.push(record.key)
    }
    // 比较rowKeys和noServiceArr，看看noServiceArr是否有值在rowKeys里面
    for (let i = 0; i < noServiceArr.length; i++) {
      for (let j = 0; j < rowKeys.length; j++) {
        if (noServiceArr[i] === rowKeys[j]) {
          payDisable.value = true
          return false
        }
      }
    }
    selectPayArr.val = rowKeys
    payDisable.value = false
  } else {
    selectPayArr.val = []
    payDisable.value = true
  }
  // return 按钮
  if (rowKeys.length > 0) {
    let returnArr = []
    for (let i = 0; i < rowKeys.length; i++) {
      for (let j = 0; j < listData.value.length; j++) {
        if (rowKeys[i] === listData.value[j].key) {
          returnArr.push(listData.value[j].shipment)
        }
      }
    }
    returnDisable.value = returnArr.every(val => val === 1)
  } else {
    returnDisable.value = false
  }
}
const manifestSelect = ref([])
const manifestArr = ref([])
const checkedAllManifest = ref(false)
// manifest 多选
const selectAllManifest = (checked) => {
  if (!checked) {
    checkedAllManifest.value = false
    manifestSelect.value = []
    manifestArr.value = []
  } else {
    checkedAllManifest.value = true
    listData.value.forEach(item => {
      if (item.status === 1) {
        let orderId = item.children.map((item) => { return item.id })
        manifestSelect.value = manifestSelect.value.concat(orderId)
        manifestArr.value = manifestArr.value.concat(item.children.map((item) => { return item.key }))
        manifestSelect.value = [...new Set(manifestSelect.value)]
      }
    })
  }
}

// manifest 单选
const selectManifest = (rowKeys) => {
  manifestArr.value = rowKeys
  manifestSelect.value = []
  rowKeys.forEach((item, key) => {
    if (String(item).indexOf('_') !== -1) {
      let orderId = Number(item.split('_')[1])
      manifestSelect.value.push(orderId)
    }
  })
  console.log(manifestArr.value)
}
// manifet 批量remove
const removeManifet = async () => {
  load.show('Removing order(s)...')
  const msg = await manifestRemove({
    order_ids: manifestSelect.value
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'Orders has/ have been removed.'
    })
    getManifestList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// manifest批量cancel
const cancelManifestMore = async () => {
  load.show('Canceling the order(s)...')
  const msg = await orderBatchHandle({
    order_ids: manifestSelect.value,
    handle_type: 4
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'The order has been canceled.'
    })
    getManifestList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// -------------------------------------------------------------------------------------
// 获取窗口的高度
const tableHeight = reactive({ value: 0 })
const tableWidth = ref(0)
tableHeight.value = document.body.clientHeight - 455
tableWidth.value = document.body.clientWidth - 50
onMounted(() => {
  // 改变窗口高度
  window.onresize = () => {
    tableWidth.value = document.body.clientWidth - 50
    if (flag.value) {
      tableHeight.value = document.body.clientHeight - 545
    } else {
      tableHeight.value = document.body.clientHeight - 455
    }
  }
})
const showSearch = () => {
  flag.value = !flag.value
  if (flag.value) {
    tableHeight.value = document.body.clientHeight - 545
  } else {
    tableHeight.value = document.body.clientHeight - 455
  }
}

// 去编辑页面
const goedit = (info) => {
  let routerUrl = router.resolve({ path: '/order/editorder', query: { orderId: info } })
  window.open(routerUrl.href, '_blank')
}
// 去详情页面
const godetail = (msg) => {
  let routerUrl = router.resolve({ path: '/order/list/listdetail', query: { orderId: msg } })
  window.open(routerUrl.href, '_blank')
}
// 预览:awaiting shipment
const preview = (url) => {
  window.open(url, '_blank', 'top=0,left=0,width=500,height=880,toolbar=0,resizable=0,statusbar=0,scrollbars=0')
}
// 打印:awaiting shipment
const print = async (url, printId) => {
  window.open(url, '_blank', 'top=0,left=0,width=500,height=880,toolbar=0,resizable=0,statusbar=0,scrollbars=0')
  const msg = await printLabel({
    order_id: printId
  })
  if (msg.code === 0) {
    Notification.success({
      title: 'Success',
      content: 'Printed.'
    })
    listType.value = 3
    navselect.value = [3]
    currentpage.value = 1
    getList()
  } else {
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 批量getLabel: awaiting payment
async function getPayLabel () {
  load.show('Pay & Getting labels...')
  noServiceArr.value = []
  const msg = await orderBatchHandle({
    order_ids: selectPayArr.val,
    handle_type: 2
  })
  if (msg.data.totalCount === msg.data.success) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'Paid & Get labels.'
    })
    selectPayArr.val = []
    selectArr.val = []
    listType.value = 2
    navselect.value = [2]
    currentpage.value = 1
    getList()
  } else if (msg.data.success === 0) {
    load.hide()
    Notification.error({
      title: 'Error',
      content: 'Failed to get the label(s).'
    })
  } else {
    load.hide()
    // 余额不足
    Notification.warning({
      id: 'payOrderlist',
      title: 'Insufficient Balance!',
      content: 'The balance is insufficient, getting labels has been stopped, and the order will continue automatically after recharging.',
      duration: 0,
      closable: true,
      footer: <Button type="primary" size="small" shape="round" onClick={closeNotification}>
        Recharge
      </Button>
    })
  }
}
const closeNotification = () => {
  Notification.remove('payOrderlist')
  let routerUrl = router.resolve({ path: '/usercenter/recharge' })
  window.open(routerUrl.href, '_blank')
}
// 批量删除: awaiting payment
async function moreDelete () {
  load.show('Deleting...')
  const msg = await orderBatchHandle({
    order_ids: selectArr.val,
    handle_type: 4
  })
  if (msg.data.totalCount === msg.data.success) {
    load.hide()
    selectArr.val = []
    Notification.success({
      title: 'Success',
      content: 'Deleted.'
    })
    listType.value = 1
    navselect.value = [1]
    currentpage.value = 1
    getList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: 'Failed to get the label(s).'
    })
  }
}
// 批量打印：awaiting shipment
async function morePrint () {
  load.show('Printing...')
  const msg = await orderBatchHandle({
    order_ids: selectArr.val,
    handle_type: 3
  })
  if (msg.data.totalCount === msg.data.success) {
    window.open(process.env.VUE_APP_ADSLINK + msg.data.labelPath, '_blank', 'top=0,left=0,width=500,height=880,toolbar=0,resizable=0,statusbar=0,scrollbars=0')
    selectArr.val = []
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'The order has been printed.'
    })
    listType.value = 3
    navselect.value = [3]
    currentpage.value = 1
    getList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: 'Failed to print.'
    })
  }
}
// 批量复制：awaiting shipment
async function moreDuplicate () {
  load.show('Duplicating...')
  const msg = await orderBatchHandle({
    order_ids: selectArr.val,
    handle_type: 5
  })
  if (msg.data.totalCount === msg.data.success) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'Duplicated.'
    })
    searchForm.lookFor = ''
    searchForm.service = [0, 101, 102]
    searchForm.orderType = ['0', '1']
    searchForm.tracking = ''
    searchForm.recipientZip = ''
    searchForm.orderId = ''
    searchForm.createTime = ['', '']
    listType.value = 1
    navselect.value = [1]
    currentpage.value = 1
    getList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: 'Failed to Duplicate.'
    })
  }
}
// 批量return:awaiting shipment
async function moreReturn () {
  load.show('Creating return Order(s)...')
  const msg = await orderBatchHandle({
    order_ids: selectArr.val,
    handle_type: 6
  })
  if (msg.data.totalCount === msg.data.success) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'Return label(s) has/ have been created.'
    })
    listType.value = 1
    navselect.value = [1]
    currentpage.value = 1
    getList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: 'Failed to create return labels.'
    })
  }
}
// 批量取消：awaiting shipment
async function moreCancel () {
  load.show('Canceling the order(s) ...')
  const msg = await orderBatchHandle({
    order_ids: selectArr.val,
    handle_type: 4
  })
  if (msg.data.success === msg.data.totalCount) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'The order has been canceled.'
    })
    currentpage.value = 1
    getList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: 'Failed to cancel the order.'
    })
  }
}
// 批量 Mark as Shipped:printed
async function moreShipped () {
  load.show('Loading...')
  const msg = await markAsShipped({
    order_ids: selectArr.val
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'The order(s) has/have been marked as shipped.'
    })
    listType.value = 4
    navselect.value = [4]
    currentpage.value = 1
    getList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: 'Failed to mark as shipped,please try again.'
    })
  }
}

// 创建createManifest
const manifestOk = async () => {
  load.show('Creating Manifest...')
  const msg = await manifestAdd({
    order_ids: selectArr.val
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'Manifest has/ have been created.'
    })
    searchForm.category = 'manifest'
    titleShow.value = 'Manifest'
    navShow.val = navList.manifest
    listType.value = 6
    navselect.value = [6]
    getManifestList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// manifest get label
const getmanifestLabel = async (labelId) => {
  const msg = await manifestPdf({
    id: labelId
  })
  if (msg.code === 0) {
    window.open(msg.data.pdf, '_blank', 'top=0,left=0,width=500,height=880,toolbar=0,resizable=0,statusbar=0,scrollbars=0')
  } else {
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// manifest去shipment
const goShipment = (manifestid, manifestname) => {
  router.push('/shipment?id=' + manifestid + '&name=' + manifestname)
}
// 自动填充zip
const numReg = /^[0-9]*$/
const fromOptions = ref([])
const fromNoarea = ref(false)
const fromIsempty = ref(true)
const fromZiperror = ref(false)
const fromLoading = ref(false)
const fromZipSearch = async (value) => {
  if (!value) {
    fromIsempty.value = true
    fromNoarea.value = false
    fromZiperror.value = false
  } else {
    fromIsempty.value = false
  }
  if (!numReg.test(value)) {
    fromZiperror.value = true
    fromNoarea.value = false
  } else {
    fromZiperror.value = false
    if (value.length > 2) {
      fromLoading.value = true
      const msg = await getZipCode({
        term: value
      })
      if (msg.data.length > 0) {
        fromLoading.value = false
        fromNoarea.value = false
        fromOptions.value = []
        for (let i = 0; i < msg.data.length; i++) {
          fromOptions.value.push(msg.data[i].zip)
        }
      } else {
        fromLoading.value = false
        fromOptions.value = []
        fromNoarea.value = true
      }
    }
  }
}
// const fromZipSearch = async (value) => {
//   if (value.length > 0) {
//     const msg = await getZipCode({
//       term: value
//     })
//     if (msg.data) {
//       fromOptions.value = msg.data
//     } else {
//       fromOptions.value = []
//     }
//   }
//   if (value.length > 5) {
//     searchForm.recipientZip = value
//   }
// }
// 通过zip回填recipient
const fromChange = (value) => {
  if (fromOptions.value !== []) {
    fromOptions.value.forEach((item) => {
      if (item.value === value) {
        searchForm.recipientZip = item.zip
      }
    })
  }
}

// 重置搜索
const handleReset = () => {
  uploadId = null
  searchForm.lookFor = ''
  searchForm.orderType = ['0', '1']
  searchForm.tracking = ''
  searchForm.recipientZip = ''
  searchForm.orderId = ''
  searchForm.createTime = ['', '']
  currentpage.value = 1
  if (listType.value === 1) {
    searchForm.service = [0, 101, 102]
  } else {
    searchForm.service = [101, 102]
  }
  if (searchForm.category === 'manifest') {
    getManifestList()
  } else {
    getList()
  }
}
// 穿梭框--------------------------------------------------
const setFlag = ref(false)
const setSelection = {
  type: 'checkbox',
  showCheckedAll: true
}
const regularData = [
  {
    key: 'trackingid',
    title: 'Tracking',
    dataIndex: 'trackingid',
    slotName: 'trackingid',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 150
  },
  {
    key: 'ponumber',
    title: 'PO Number',
    dataIndex: 'ponumber',
    slotName: 'ponumber',
    sortable: {
      sortDirections: ['ascend', 'descend']
    },
    width: 185
  },
  {
    key: 'orderid',
    title: 'Order ID',
    slotName: 'orderid',
    dataIndex: 'orderid',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 200
  },
  {
    key: 'createat',
    title: 'Created At',
    dataIndex: 'createat',
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b, extra) => {
        let sortVal
        if (a.key > b.key) {
          sortVal = 1
        } else if (a.key < b.key) {
          sortVal = -1
        } else {
          sortVal = 0
        }
        return (extra.direction === 'ascend') ? sortVal : -sortVal
      }
    },
    width: 200
  },
  {
    key: 'service',
    title: 'Service Type',
    dataIndex: 'service',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 150
  },
  {
    key: 'shipment',
    title: 'Shipment',
    dataIndex: 'shipment',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 120
  },
  {
    key: 'price',
    title: 'Est. Cost',
    dataIndex: 'price',
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b, extra) => {
        let sortVal;
        if (parseFloat(a.price) > parseFloat(b.price)) {
          sortVal = 1;
        } else if (parseFloat(a.price) < parseFloat(b.price)) {
          sortVal = -1;
        } else {
          sortVal = 0;
        }
        return (extra.direction === 'ascend') ? sortVal : -sortVal;
      }
    },
    slotName: 'price',
    width: 150
  },
  {
    key: 'recipientname',
    title: 'Recipient Name',
    dataIndex: 'recipientname',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 180
  },
  {
    key: 'ordertype',
    title: 'Order Type',
    width: 180,
    dataIndex: 'ordertype',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'incoivenum',
    title: 'Invoice Number',
    width: 180,
    dataIndex: 'incoivenum',
    slotName: 'incoivenum',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'reference',
    title: 'Reference',
    width: 130,
    slotName: 'reference',
    dataIndex: 'reference',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'signiture',
    title: 'Signiture Option',
    width: 220,
    dataIndex: 'signiture',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'droptype',
    title: 'Drop Off Type',
    width: 180,
    dataIndex: 'droptype',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'shipdate',
    title: 'Ship Date',
    width: 130,
    slotName: 'shipdate',
    dataIndex: 'shipdate',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'totalweight',
    title: 'Weight(lb)',
    width: 180,
    dataIndex: 'totalweight',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'recipientfulladdress',
    title: 'Recipient Full Address',
    width: 220,
    dataIndex: 'recipientfulladdress',
    slotName: 'recipientfulladdress',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'recipientaddress1',
    title: 'Recipient Address1',
    width: 200,
    dataIndex: 'recipientaddress1',
    slotName: 'recipientaddress1',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'recipientaddress2',
    title: 'Recipient Address2',
    width: 200,
    dataIndex: 'recipientaddress2',
    slotName: 'recipientaddress2',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'recipientcity',
    title: 'Recipient City',
    width: 180,
    dataIndex: 'recipientcity',
    slotName: 'recipientcity',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'recipientstate',
    title: 'Recipient State',
    width: 180,
    dataIndex: 'recipientstate',
    slotName: 'recipientstate',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'recipientzip',
    title: 'Recipient Zip',
    width: 150,
    dataIndex: 'recipientzip',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'recipientphone',
    title: 'Recipient Phone',
    width: 180,
    dataIndex: 'recipientphone',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'recipientcompany',
    title: 'Recipient Company',
    width: 200,
    dataIndex: 'recipientcompany',
    slotName: 'recipientcompany',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'shipperfulladdress',
    title: 'Shipper Full Address',
    width: 220,
    dataIndex: 'shipperfulladdress',
    slotName: 'shipperfulladdress',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'shippername',
    title: 'Shipper Name',
    width: 180,
    dataIndex: 'shippername',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'shipperaddress1',
    title: 'Shipper Address1',
    width: 180,
    dataIndex: 'shipperaddress1',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'shipperaddress2',
    title: 'Shipper Address2',
    width: 180,
    slotName: 'shipperaddress2',
    dataIndex: 'shipperaddress2',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'shippercity',
    title: 'Shipper City',
    width: 180,
    dataIndex: 'shippercity',
    slotName: 'shippercity',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'shipperstate',
    title: 'Shipper State',
    width: 180,
    dataIndex: 'shipperstate',
    slotName: 'shipperstate',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'shipperzip',
    title: ' Shipper Zip',
    width: 180,
    dataIndex: 'shipperzip',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'shipperphone',
    title: 'Shipper Phone',
    width: 180,
    dataIndex: 'shipperphone',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'shippercompany',
    title: 'Shipper Company',
    width: 180,
    slotName: 'shippercompany',
    dataIndex: 'shippercompany',
    sortable: { sortDirections: ['ascend', 'descend'] }
  }
]
const callChangeData = [
  {
    key: 'trackingid',
    title: 'Tracking',
    dataIndex: 'trackingid',
    slotName: 'trackingid',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 150
  },
  {
    key: 'ponumber',
    title: 'PO Number',
    dataIndex: 'ponumber',
    slotName: 'ponumber',
    sortable: {
      sortDirections: ['ascend', 'descend']
    },
    width: 200
  },
  {
    key: 'orderid',
    title: 'Order ID',
    slotName: 'orderid',
    dataIndex: 'orderid',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 200
  },
  {
    key: 'original',
    title: 'Original Order Id',
    dataIndex: 'original',
    sortable: { sortDirections: ['ascend', 'descend'] },
    slotName: 'originalponumber',
    width: 210
  },
  {
    key: 'createat',
    title: 'Created At',
    dataIndex: 'createat',
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b, extra) => {
        let sortVal
        if (a.key > b.key) {
          sortVal = 1
        } else if (a.key < b.key) {
          sortVal = -1
        } else {
          sortVal = 0
        }
        return (extra.direction === 'ascend') ? sortVal : -sortVal
      }
    },
    width: 200
  },
  {
    key: 'service',
    title: 'Service Type',
    dataIndex: 'service',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 150
  },
  {
    key: 'shipment',
    title: 'Shipment',
    dataIndex: 'shipment',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 120
  },
  {
    key: 'price',
    title: 'Est. Cost',
    dataIndex: 'price',
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b, extra) => {
        let sortVal;
        if (parseFloat(a.price) > parseFloat(b.price)) {
          sortVal = 1;
        } else if (parseFloat(a.price) < parseFloat(b.price)) {
          sortVal = -1;
        } else {
          sortVal = 0;
        }
        return (extra.direction === 'ascend') ? sortVal : -sortVal;
      }
    },
    slotName: 'price',
    width: 150
  },
  {
    key: 'recipientname',
    title: 'Recipient Name',
    dataIndex: 'recipientname',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 180
  },
  {
    key: 'ordertype',
    title: 'Order Type',
    width: 180,
    dataIndex: 'ordertype',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'incoivenum',
    title: 'Invoice Number',
    width: 180,
    dataIndex: 'incoivenum',
    slotName: 'incoivenum',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'reference',
    title: 'Reference',
    width: 130,
    slotName: 'reference',
    dataIndex: 'reference',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'signiture',
    title: 'Signiture Option',
    width: 220,
    dataIndex: 'signiture',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'droptype',
    title: 'Drop Off Type',
    width: 180,
    dataIndex: 'droptype',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'shipdate',
    title: 'Ship Date',
    width: 130,
    slotName: 'shipdate',
    dataIndex: 'shipdate',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'totalweight',
    title: 'Weight(lb)',
    width: 180,
    dataIndex: 'totalweight',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'recipientfulladdress',
    title: 'Recipient Full Address',
    width: 220,
    dataIndex: 'recipientfulladdress',
    slotName: 'recipientfulladdress',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'recipientaddress1',
    title: 'Recipient Address1',
    width: 200,
    dataIndex: 'recipientaddress1',
    slotName: 'recipientaddress1',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'recipientaddress2',
    title: 'Recipient Address2',
    width: 200,
    dataIndex: 'recipientaddress2',
    slotName: 'recipientaddress2',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'recipientcity',
    title: 'Recipient City',
    width: 180,
    dataIndex: 'recipientcity',
    slotName: 'recipientcity',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'recipientstate',
    title: 'Recipient State',
    width: 180,
    dataIndex: 'recipientstate',
    slotName: 'recipientstate',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'recipientzip',
    title: 'Recipient Zip',
    width: 150,
    dataIndex: 'recipientzip',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'recipientphone',
    title: 'Recipient Phone',
    width: 180,
    dataIndex: 'recipientphone',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'recipientcompany',
    title: 'Recipient Company',
    width: 200,
    dataIndex: 'recipientcompany',
    slotName: 'recipientcompany',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'shipperfulladdress',
    title: 'Shipper Full Address',
    width: 220,
    dataIndex: 'shipperfulladdress',
    slotName: 'shipperfulladdress',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'shippername',
    title: 'Shipper Name',
    width: 180,
    dataIndex: 'shippername',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'shipperaddress1',
    title: 'Shipper Address1',
    width: 180,
    dataIndex: 'shipperaddress1',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'shipperaddress2',
    title: 'Shipper Address2',
    width: 180,
    slotName: 'shipperaddress2',
    dataIndex: 'shipperaddress2',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'shippercity',
    title: 'Shipper City',
    width: 180,
    dataIndex: 'shippercity',
    slotName: 'shippercity',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'shipperstate',
    title: 'Shipper State',
    width: 180,
    dataIndex: 'shipperstate',
    slotName: 'shipperstate',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'shipperzip',
    title: ' Shipper Zip',
    width: 180,
    dataIndex: 'shipperzip',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'shipperphone',
    title: 'Shipper Phone',
    width: 180,
    dataIndex: 'shipperphone',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    key: 'shippercompany',
    title: 'Shipper Company',
    width: 180,
    slotName: 'shippercompany',
    dataIndex: 'shippercompany',
    sortable: { sortDirections: ['ascend', 'descend'] }
  }
]
let leftData = ref(regularData)
// 改变排序
const handleChange = (_data) => {
  leftData.value = _data
  let newArr = ref([])
  _data.forEach(item => {
    if (setArr.val.indexOf(item.key) !== -1) {
      newArr.value.push(item.key)
    }
  })
  setArr.val = newArr.value
}
const setArr = reactive({
  val: ['trackingid', 'ponumber', 'orderid', 'createat', 'service', 'shipment', 'price', 'recipientname']
})
const isSave = ref(true)
// 设置全选
const selectAll = (checked) => {
  if (checked) {
    setArr.val = []
    leftData.value.forEach(item => {
      setArr.val.push(item.key)
    })
    isSave.value = false
  } else {
    setArr.val = []
    isSave.value = true
  }
  defaultSelect.value = setArr.val
}
// 设置单选
const setSelect = (rowKeys) => {
  if (rowKeys.length > 0) {
    setArr.val = []
    leftData.value.forEach(item => {
      if (rowKeys.indexOf(item.dataIndex) !== -1) {
        setArr.val.push(item.key)
      }
    })
  } else {
    setArr.val = []
  }
  defaultSelect.value = setArr.val
}
// 点击设置
const setVal = () => {
  const action = ref([{
    title: 'Action',
    dataIndex: 'action',
    fixed: 'right',
    slotName: 'action',
    width: 150
  }])
  let saveArr = ref([])
  for (let i = 0; i < setArr.val.length; i++) {
    for (let j = 0; j < leftData.value.length; j++) {
      if (leftData.value[j].dataIndex === setArr.val[i]) {
        saveArr.value.push(leftData.value[j])
      }
    }
  }
  saveArr.value = saveArr.value.concat(action.value)
  if (searchForm.category === 'regular') {
    listColumns.value = saveArr.value
  } else if (searchForm.category === 'manifest') {
    listManifestColumns.value = saveArr.value
  } else {
    listcallColumns.value = saveArr.value
  }
  Notification.success({
    title: 'Success',
    content: 'Saved.'
  })
  setFlag.value = false
}
const defaultSelect = ref(['trackingid', 'ponumber', 'orderid', 'createat', 'service', 'shipment', 'price', 'recipientname'])
// 点击default
const setDefault = () => {
  if (searchForm.category === 'regular') {
    leftData.value = regularData
    defaultSelect.value = ['trackingid', 'ponumber', 'orderid', 'createat', 'service', 'shipment', 'price', 'recipientname']
    setArr.val = ['trackingid', 'ponumber', 'orderid', 'createat', 'service', 'shipment', 'price', 'recipientname']
  }
  if (searchForm.category === 'cc') {
    leftData.value = callChangeData
    defaultSelect.value = ['trackingid', 'ponumber', 'orderid', 'original', 'createat', 'service', 'shipment', 'price', 'recipientname']
    setArr.val = ['trackingid', 'ponumber', 'orderid', 'original', 'createat', 'service', 'shipment', 'price', 'recipientname']
  }
}
// 点击其他地方关闭设置
onMounted(() => {
  let box = document.getElementsByClassName('transfer')[0]
  let btn = document.getElementById('setBtn')
  btn.addEventListener('click', function (e) {
    setFlag.value = !setFlag.value
    e.stopPropagation()
  })
  box.addEventListener('click', function (e) {
    e.stopPropagation()
  })
  document.addEventListener('click', function (e) {
    setFlag.value = false
  })
})
// 刷新页面------------------------------------
const refreshData = JSON.parse(local.get('searchData'))
if (Golist.value || uploadId) {
  listType.value = Golist.value
  navselect.value = [Golist.value]
  getList()
} else if (refreshData) {
  searchForm.lookFor = refreshData.formData.lookFor
  searchForm.category = refreshData.formData.category
  searchForm.service = refreshData.formData.service
  searchForm.orderType = refreshData.formData.orderType
  searchForm.tracking = refreshData.formData.tracking
  searchForm.recipientZip = refreshData.formData.recipientZip
  searchForm.orderId = refreshData.formData.orderId
  searchForm.createTime = refreshData.formData.createTime
  navShow.val = refreshData.nav
  titleShow.value = refreshData.title
  navselect.value = refreshData.navselect
  listType.value = refreshData.listType
  noDefault.value = refreshData.listType
  flag.value = refreshData.flag
  if (searchForm.category === 'regular') {
    leftData.value = regularData
    defaultSelect.value = ['trackingid', 'ponumber', 'orderid', 'createat', 'service', 'shipment', 'price', 'recipientname']
    setArr.val = ['trackingid', 'ponumber', 'orderid', 'createat', 'service', 'shipment', 'price', 'recipientname']
  }
  if (searchForm.category === 'cc') {
    leftData.value = callChangeData
    defaultSelect.value = ['trackingid', 'ponumber', 'orderid', 'original', 'createat', 'service', 'shipment', 'price', 'recipientname']
    setArr.val = ['trackingid', 'ponumber', 'orderid', 'original', 'createat', 'service', 'shipment', 'price', 'recipientname']
  }
  if (refreshData.title === 'Manifest') {
    currentpageManifest.value = refreshData.currentpage
    pageSizeManifest.value = refreshData.pageSizeManifest
    getManifestList()
  } else {
    currentpage.value = refreshData.currentpage
    pageSize.value = refreshData.pageSize
    getList()
  }
} else {
  flag.value = false
  getList()
}
const checkedValue = ref(false)
// 点击复制
const copyVal = (val) => {
  const input = document.createElement("input")
  document.body.appendChild(input)
  input.setAttribute("value", val)
  input.select()
  if (document.execCommand("copy")) {
    document.execCommand("copy")
    Message.success(val + '  ' + 'copied.')
  }
  document.body.removeChild(input)
}
const selectPage = (val) => {
  pageSize.value = val
  getList()
}
const selectPageManifest = (val) => {
  pageSizeManifest.value = val
  getManifestList()
}
</script>
<style scoped lang="less">
.empty {
  color: rgb(var(--danger-6));
  font-weight: 400;
  font-size: 14px;
  padding: 8px 12px;
}

.noarea {
  color: var(--color-text-4);
  font-weight: 400;
  font-size: 14px;
  padding: 8px 12px;
}

.margin-l-10 {
  cursor: pointer;
  width: 12px;
  height: 12px;
  color: #C9CDD4;
}

.empty-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    color: rgba(28, 31, 35, 0.8);
    font-weight: 400;
    font-size: 14px;
    padding-top: 30px;
  }
}

:deep(.arco-spin) {
  display: block;
}

.searchOrder {
  padding: 0 24px;
}

.list-title {
  font-weight: 500;
  font-size: 20px;
  color: var(--color-text-1);
  padding: 18px 0 18px 24px;
}

.operate {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  height: 82px;
}

.menu-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding-bottom: 20px;
  background-color: #fff;
  border-radius: 8px 8px 0 0;
  margin-top: 24px;

  .nav-cont {
    display: flex;
    align-items: center;

    .statType {
      font-weight: 500;
      font-size: 16px;
      padding-right: 20px;
    }
  }
}

.topoperate {
  padding: 24px 0;
  width: 700px;

  :deep(.arco-menu-light .arco-menu-item.arco-menu-selected) {
    background-color: var(--color-fill-2);
  }

  :deep(.arco-menu-selected-label) {
    background-color: transparent;
  }
}

.add-page {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;

  .arco-btn-text.arco-btn-disabled,
  .arco-btn-text[type='button'].arco-btn-disabled {
    color: var(--color-text-4);
  }

  .arco-btn-text,
  .arco-btn-text[type='button'] {
    color: var(--color-text-2);
  }
}

.content-list {
  position: relative;
}

//穿梭框
.transfer {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.97);
  z-index: 999;

  .setList {
    position: relative;

    :deep(.arco-table-container thead>tr th:first-child) {
      display: none;
    }

    :deep(.arco-table-container thead>tr th:nth-child(3))>.arco-table-cell {
      width: 100px;
      font-size: 14px;
      color: #1d2129;
      font-weight: 500;
    }

    :deep(.arco-table-container .arco-table-content) {
      border: 1px solid #E5E6EB;
      border-bottom: none;
      border-radius: 0;
    }

    :deep(.arco-table-container) {
      border-radius: 0 !important;
    }

    :deep(.arco-table-header) {
      height: 40px;
      background: #F7F8FA;
    }

    :deep(.arco-table-tr) {
      height: 40px;
    }

    :deep(.arco-table-tr .arco-table-td) {
      border: 0;
      color: #1d2129;
    }

    :deep(.arco-table .arco-table-cell) {
      padding: 9px 0;
    }

    :deep(.arco-table-tr-checked .arco-table-td) {
      background: #fff !important;
    }

    .SelectNum {
      position: absolute;
      top: 12px;
      right: 8px;
      font-weight: 400;
      font-size: 12px;
      color: #86909C;
    }

    .transfer-btn {
      height: 40px;
      background: #fff;
      border: 1px solid #E5E6EB;
      border-radius: 0 0 4px 4px;
      width: 218px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        margin: 0 8px;
      }
    }
  }
}

.addnew-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 48px;
  border-top: 1px solid var(--color-border-2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 999;
}

.items {
  font-weight: 400;
  font-size: 14px;
  color: var(--color-text-1);
  margin-right: 48px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.warn-tips {
  display: flex;
}

.quanty {
  padding-bottom: 13px;

  .total-q-til {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-text-3);
  }

  .total-q-num {
    font-weight: 600;
    font-size: 24px;
    color: var(--color-text-1);
    padding-right: 6px;
  }
}

.pay-tip {
  font-weight: 400;
  font-size: 12px;
  color: var(--color-text-3);
  line-height: 22px;

  span {
    font-weight: 700;
    font-size: 14px;
    color: rgb(var(--primary-6));
  }
}

.itemNum {
  height: 36px;
}

.price-tip {
  line-height: 24px;
  color: var(--color-text-1);
  background-color: var(--color-fill-2);
  border-radius: 8px;
  padding: 1px 8px;
}

.originalOrder {
  cursor: pointer;
  color: rgb(var(--primary-6));
  padding-right: 10px;
}

.currentpage {
  color: rgb(var(--primary-6));
  background-color: var(--color-primary-light-1);
  padding: 1px 8px;
  display: inline-block;
  box-sizing: border-box;
}
</style>
<style>
.warnPop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--warning-6));
  background-color: rgb(var(--warning-1));
}

.deletePop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--danger-6));
  background-color: rgb(var(--danger-1));
}
</style>
